import { memo, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import clsx from "clsx";
import { v4 as uuid } from "uuid";

const Checkbox = memo(({ label, ...props }) => (
  <div className="checkbox-container">
    <label className="checkbox-label">
      <input {...props} type="checkbox" className="checkbox-input" />
      <div className="checkbox-box" />
      <p className="checkbox-text">{label}</p>
    </label>
  </div>
));

Checkbox.displayName = "Checkbox";

const App = () => {
  const [values, setValues] = useState({
    full_name: "",
    company: "",
    function: "",
    email: "",
    phone: "",
    recaptcha: "",
    goWithGuest: false,
    guests: [{ id: uuid(), full_name: "", email: "" }],
  });
  const [errors, setErrors] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const recaptchaRef = useRef(null);

  const onChange = (key, value) => {
    return setValues((rest) => ({ ...rest, [key]: value }));
  };

  const onGuestChange = (key, id, value) => {
    const newGuests = [...values.guests];
    const index = newGuests.findIndex((item) => item.id === id);

    newGuests[index][key] = value;

    setValues((rest) => ({ ...rest, guests: newGuests }));
  };

  const onGuestAdd = () => {
    if (values.guests.length < 10) {
      const newGuests = [...values.guests];

      newGuests.push({ id: uuid(), full_name: "", email: "" });

      setValues((rest) => ({ ...rest, guests: newGuests }));
    }
  };

  const onGuestRemove = (id) => {
    if (values.guests.length > 1) {
      const newGuests = [...values.guests];
      const index = newGuests.findIndex((item) => item.id === id);

      newGuests.splice(index, 1);

      setValues((rest) => ({ ...rest, guests: newGuests }));
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setIsProcessing(true);

    if (values?.success) {
      setValues((rest) => ({ ...rest, success: false }));
    }

    fetch(`${process.env.REACT_APP_API_ENDPOINT}/temp/event`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((res) => res.json())
      .then(({ status, message, data }) => {
        if (status === 200) {
          setValues({
            full_name: "",
            company: "",
            function: "",
            email: "",
            phone: "",
            recaptcha: "",
            goWithGuest: false,
            guest_full_name: "",
            guest_email: "",
            success: true,
          });

          setErrors({});
        } else {
          setErrors(data);
        }
      })
      .finally(() => {
        recaptchaRef.current.reset();
        setIsProcessing(false);
      });
  };

  return (
    <main className="container">
      <div className="image-container" />
      <div className="content">
        <h1 className="title">
          Invitation ARINFO
          <br />
          professionnels de l'orientation
        </h1>
        <span className="subtitle">
          Vendredi 6 décembre 2024 de 8h45 à 10h30
        </span>
        <form noValidate onSubmit={onSubmit} className="form">
          <p className="description">
            Nous vous convions pour un petit déjeuner entre professionnels de
            l'orientation et l’équipe Arinfo autour d’un moment de convivialité
            !
          </p>
          <p className="description">
            Merci de compléter le formulaire pour nous indiquer votre présence.
          </p>
          <p className="description">
            Si vous venez accompagné, veuillez l’indiquer dans le formulaire.
          </p>
          {values?.success ? (
            <div className="form-success">Inscription terminée</div>
          ) : (
            <>
              <div className="form-row">
                <div className="form-field">
                  <label className="form-label">Nom et prénom * :</label>
                  <input
                    name="full_name"
                    value={values.full_name}
                    onChange={(event) =>
                      onChange("full_name", event.target.value)
                    }
                    disabled={isProcessing}
                    className={clsx("form-input", { error: errors?.full_name })}
                  />
                  {errors?.full_name && (
                    <small className="form-error">{errors?.full_name}</small>
                  )}
                </div>
                <div className="form-field form-field-filler" />
              </div>
              <div className="form-row">
                <div className="form-field">
                  <label className="form-label">Email * :</label>
                  <input
                    name="email"
                    value={values.email}
                    onChange={(event) => onChange("email", event.target.value)}
                    disabled={isProcessing}
                    className={clsx("form-input", { error: errors?.email })}
                  />
                  {errors?.email && (
                    <small className="form-error">{errors?.email}</small>
                  )}
                </div>
                <div className="form-field">
                  <label className="form-label">Téléphone :</label>
                  <input
                    name="phone"
                    value={values.phone}
                    maxLength={10}
                    onChange={(event) =>
                      onChange(
                        "phone",
                        event.target.value.replace(/[^0-9]/g, "")
                      )
                    }
                    disabled={isProcessing}
                    className={clsx("form-input", { error: errors?.phone })}
                  />
                  {errors?.phone && (
                    <small className="form-error">{errors?.phone}</small>
                  )}
                </div>
              </div>
              <div className="form-row">
                <div className="form-field">
                  <label className="form-label">
                    Nom de l'entreprise ou structure * :
                  </label>
                  <input
                    name="company"
                    value={values.company}
                    onChange={(event) =>
                      onChange("company", event.target.value)
                    }
                    disabled={isProcessing}
                    className={clsx("form-input", { error: errors?.company })}
                  />
                  {errors?.company && (
                    <small className="form-error">{errors?.company}</small>
                  )}
                </div>
                <div className="form-field">
                  <label className="form-label">
                    Poste au sein de cette entreprise/structure :
                  </label>
                  <input
                    name="function"
                    value={values.function}
                    onChange={(event) =>
                      onChange("function", event.target.value)
                    }
                    disabled={isProcessing}
                    className={clsx("form-input", { error: errors?.function })}
                  />
                  {errors?.function && (
                    <small className="form-error">{errors?.function}</small>
                  )}
                </div>
              </div>
              <div className="form-field">
                <Checkbox
                  label="Je viens accompagné"
                  name="java-jee"
                  checked={values.goWithGuest}
                  onChange={(event) =>
                    setValues((rest) => ({
                      ...rest,
                      goWithGuest: event.target.checked,
                    }))
                  }
                  disabled={isProcessing}
                />
              </div>
              {values.goWithGuest && (
                <>
                  {values.guests.map((item, index) => (
                    <div className="guest" key={item.id}>
                      <div className="guest-header">
                        {values.guests.length > 1 && (
                          <button
                            type="button"
                            onClick={() => onGuestRemove(item.id)}
                            className="form-button-delete"
                          >
                            Supprimer
                          </button>
                        )}
                        <p style={{ fontWeight: 600 }}>INVITÉ {index + 1}</p>
                      </div>
                      <div className="form-row">
                        <div className="form-field">
                          <label className="form-label">
                            Nom et prénom de l'invité * :
                          </label>
                          <input
                            name="full_name"
                            value={item.full_name}
                            onChange={(event) =>
                              onGuestChange(
                                "full_name",
                                item.id,
                                event.target.value
                              )
                            }
                            disabled={isProcessing}
                            className={clsx("form-input", {
                              error: errors?.[`${item.id}-full_name`],
                            })}
                          />
                          {errors?.[`${item.id}-full_name`] && (
                            <small className="form-error">
                              {errors?.[`${item.id}-full_name`]}
                            </small>
                          )}
                        </div>
                        <div className="form-field">
                          <label className="form-label">
                            Email de l'invité * :
                          </label>
                          <input
                            name="email"
                            value={item.email}
                            onChange={(event) =>
                              onGuestChange(
                                "email",
                                item.id,
                                event.target.value
                              )
                            }
                            disabled={isProcessing}
                            className={clsx("form-input", {
                              error: errors?.[`${item.id}-email`],
                            })}
                          />
                          {errors?.[`${item.id}-email`] && (
                            <small className="form-error">
                              {errors?.[`${item.id}-email`]}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                  {values.guests.length < 10 && (
                    <button
                      type="button"
                      onClick={onGuestAdd}
                      className="form-button"
                      style={{ width: "100%", marginBottom: 20 }}
                    >
                      Ajouter un invité
                    </button>
                  )}
                </>
              )}
              <div className="form-field" style={{ marginTop: 10 }}>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  onChange={(response) => onChange("recaptcha", response)}
                />
                {errors?.recaptcha && (
                  <small className="form-error">{errors?.recaptcha}</small>
                )}
              </div>
              <p className="description" style={{ textAlign: "center" }}>
                Vous recevrez un mail de confirmation une fois le formulaire
                envoyé
              </p>
              <button
                type="submit"
                disabled={isProcessing}
                className="form-button"
              >
                {isProcessing ? "Chargement" : "Envoyer"}
              </button>
            </>
          )}
          <hr style={{ borderColor: "#f5f5fb", marginTop: 20 }} />
          <p className="form-rgpd">
            Les données recueillies par ce formulaire ont pour unique but de
            préparer l'évènement du 6 décembre 2024 et ne seront pas
            réutilisées.
          </p>
          <div className="links">
            <a
              href="https://arinfo.fr/mentions-legales"
              target="_blank"
              rel="nopenner noreferrer"
            >
              Mentions légales
            </a>
            <a
              href="https://arinfo.fr/conditions-generales-de-prestation-et-de-vente"
              target="_blank"
              rel="nopenner noreferrer"
            >
              CGPV
            </a>
          </div>
        </form>
      </div>
    </main>
  );
};

export default App;

